const $nav = document.querySelector('#header-top')
const threshold = $nav.getBoundingClientRect()
const thresholdMinus = threshold.top
const fixedClass = 'top-active'

const language = {
  en: {
    title_head: "Welcome to NNP Raisins",
    nav_1: "About",
    nav_2: "Products",
    nav_3: "Production",
    nav_4: "Certification",
    nav_5: "Contacts",
    header_follow_social: "Follow us",
    header_slide_text: "Nutritious,\n" +
      "sweet, and\n" +
      "always rich in vitamins\n" +
      "cONTACT raisins",
    header_slide_title: "The natural sweetness of life",
    header_scroll: "scroll down <i class=\"fal fa-long-arrow-down ms-3 fs-20\"></i>",
    header_bottom_info: "We grow the tastiest grapes - and we do it with love!",
    block_name_repeat: "NNP RAISINS",
    main_about_more: "NNP Raisins is part of the Navobod Naslli Parranda agricultural holding, which is one of the largest agricultural enterprises in the Republic of Uzbekistan. Our brand is a family-owned business and has been engaged in the production of high-quality raisins for many years.\n" +
      "<br>" +
      "<br>" +
      "\"NNP Raisins\" fully acknowledges the importance of an environmentally sustainable approach in\n" +
      "agriculture and pays special attention to this issue. We strive to minimize the negative impact on the\n" +
      "environment and apply every effort to ensure that our activities comply with all basic environmental safety standards. Our Company constantly implements environmental measures to reduce waste and\n" +
      "conserve natural resources through introduction of green technologies in our divisions",
    btn_more: "more <i class=\"far ms-3 fa-long-arrow-right\"></i>",
    main_about_name: "About us",
    main_about_company: "About",
    main_about_company_text: "Currently, the \"NNP Raisins\" brand is part of the structure of the \"Navobod Naslli Parranda\" Company,\n" +
      "one of the largest agricultural holdings in the Republic of Uzbekistan, that maintains a solid reputation\n" +
      "as a responsible, reliable manufacturer and supplier of high-quality raisins for many decades.\n" +
      "<br>" +
      "<br>" +
      "Our company has its own vineyards with a total area of 120 hectares, modern production facilities and\n" +
      "warehouse facilities located in the Samarkand region with an established logistics infrastructure, which\n" +
      "makes us possible to manufacture the high-quality products and deliver within the shortest time\n" +
      "possible. ",
    why_me: "Why choose us?",
    why_me_title_1: "Qualified",
    why_me_title_2: "Certified",
    why_me_title_3: "Direct from the Farm",
    why_me_text_1: "All our staff are qualified in their field, and we pride ourselves in empowering our employees by providing training.",
    why_me_text_2: "We are certified to the highest standards in the industry and the first & leading organic certified processor in SA.",
    why_me_text_3: "We source, pack & market our products independently to fulfil the exact needs and specifications of our valued customers.",
    what_guarantee: "What do we guarantee",
    what_guarantee_1: "EXCELLENT TASTE",
    what_guarantee_2: "TOP QUALITY",
    what_guarantee_3: "THE MOST SUITABLE PRICES",
    what_guarantee_4: "DIRECT DELIVERIES FROM UZBEKISTAN",
    we_love_work: "We do it with love!",
    main_international: "International certificate of quality management \n" +
      "and food safety FSSC 22000",
    footer_logo_text: "We grow the tastiest grapes - and we do it with love!",
    footer_address: "Address",
    footer_address_1: "140303 Kulbaipoyon SGG, Andijoni, Samarkand region, Republic of Uzbekistan",
    footer_address_2: "Uzbekistan, Tashkent, <br>Dombribod 4-tor str., 23/2.",
    footer_contact: "Contacts",
    footer_links: "Links",
    footer_copyright: "All rights reserved © NNP Raisins | Confectionery raisins wholesale ",
    about_about_text: "Currently, the \"NNP Raisins\" brand is part of the structure of the \"Navobod Naslli Parranda\" Company,\n" +
      "one of the largest agricultural holdings in the Republic of Uzbekistan, that maintains a solid reputation\n" +
      "as a responsible, reliable manufacturer and supplier of high-quality raisins for many decades.\n" +
      "<br>" +
      "<br>" +
      "Our company has its own vineyards with a total area of 120 hectares, modern production facilities and\n" +
      "warehouse facilities located in the Samarkand region with an established logistics infrastructure, which\n" +
      "makes us possible to manufacture the high-quality products and deliver within the shortest time\n" +
      "possible.\n" +
      "<br>" +
      "<br>" +
      "One of the key features of our Company is a vertically integrated structure that makes us possible to\n" +
      "control and manage the processes at all stages and in all areas of production. \n" +
      "<br>" +
      "<br>" +
      "We sincerely appreciate our Company's partners and strive for long-term and mutually beneficial\n" +
      "relationships, with paying special attention to the quality of our products, providing innovative and\n" +
      "competitive solutions.\n",
    philosophy_title: "Our philosophy",
    philosophy_text: "\"NNP Raisins\" fully acknowledges the importance of an environmentally sustainable approach in\n" +
      "agriculture and pays special attention to this issue. We strive to minimize the negative impact on the\n" +
      "environment and apply every effort to ensure that our activities comply with all basic environmental\n" +
      "safety standards. Our Company constantly implements environmental measures to reduce waste and\n" +
      "conserve natural resources through introduction of green technologies in our divisions.\n" +
      "<br>" +
      "<br>" +
      "\"NNP Raisins\" is not just a farm, it is a place where science, technology and nature are harmonized to\n" +
      "create safe and high-quality products without negative impact on the environment.",
    about_history: "Our history",
    about_history_text: "Viticulture has been an integral part of agricultural culture for centuries, a value, pride and source of\n" +
      "income for the people of Uzbekistan. The cultivation of grapes is intrinsically linked with our way of life\n" +
      "and traditions, which made the Republic of Uzbekistan as one of the global centers of viticulture.\n" +
      "<br>" +
      "<br>" +
      "The \"NNP Raisins\" Company is a national brand, a family-owned enterprise which cultivated the grapes\n" +
      "and produced the high-quality raisins for 30 years in the Samarkand region – a historic main center of\n" +
      "the raw material in the Republic of Uzbekistan.\n" +
      "<br>" +
      "<br>" +
      "Thanks to the long-term experience and hard work of the founders of our Company, \"NNP Raisins\" is\n" +
      "currently one of the most successful farms operating under a cluster model with a full production cycle.\n" +
      "<br>" +
      "<br>" +
      "We are proud of our roots and traditions. We respect and appreciate the culture of the people of\n" +
      "Uzbekistan and strive to follow the basic moral values in our work.\n" +
      "<br>" +
      "<br>" +
      "We continue to build our future – the reliable quality is the main value of our product.",
    about_development: "Sustainable development",
    about_development_text: "\"NNP Raisins\" applies a great deal of efforts and resources into development of agricultural production\n" +
      "in different regions of Uzbekistan, where our partners are located, whom we interact and work with for\n" +
      "many years.\n" +
      "<br>" +
      "<br>" +
      "Our Company supports local agricultural communities, creates jobs and promotes economic growth in\n" +
      "the region.\n" +
      "<br>" +
      "<br>" +
      "We trust our farmers whom we cooperate with, and constantly contribute to the capacity building and\n" +
      "exchange of new knowledge and skills, with implementing strict quality control during the harvesting\n" +
      "and processing of our yields. ",
    about_group: "Our team",
    about_group_text: "The managers and specialists of our company are true professionals in their disciplines, which is one of\n" +
      "the key components of the success of our farm.\n" +
      "<br>" +
      "<br>" +
      "Our specialists applies all necessary agronomic measures in a prompt and appropriate manner, including\n" +
      "pruning, watering, fertilizing and protecting from cold weather and pests along other activities\n" +
      "performed all-around the year to harvest the grapes, which is the basic feedstock for production of the\n" +
      "raisins with the premium quality.\n" +
      "<br>" +
      "<br>" +
      "During production of the raisins our specialists strictly observe the rules as follows:\n",
    about_group_text_1: "Constant monitoring of the production process and quality management;",
    about_group_text_2: "Compliance with all the hygiene and sanitary rules during the production;",
    about_group_text_3: "Ensure the correct course of the technological process during production;",
    about_group_text_4: "Ensure product quality control and uninterrupted supply;",
    about_group_text_5: "Permanent capacity building to improve their qualifications.",
    about_group_text_6: "Every future member of our team shall pass a thorough interview and selected against the available\n" +
      "required skills and knowledge to ensure smooth operation of the Company and production of highquality products.\n",
    product_repeat: "Products",
    product_title_1: "Black Raisins / Grade 3",
    product_title_2: "Raisins variety: kishmish thompson seedless, <br> Sultana / Grade 1",
    product_title_3: "Red raisins / Grade 2",
    product_title_4: "Red raisins / Grade 2",
    product_title_5: "Raisins variety / Kishmish Thompson seedless, <br> Sultana / Grade 1",
    product_title_6: "Raisins variety / Kishmish Thompson seedless, <br> Sultana / Grade 1",
    product_title_7: "Raisins variety / Kishmish Thompson seedless, <br> Sultana / Premium grade",
    product_title_8: "\"Golden\" Raisins /Kishmish, <br> Thompson seedless, <br> Sultana / Grade 1",
    product_title_9: "Golden Raisins /Kishmish, <br> Thompson seedless, <br> Sultana / Premium grade",
    product_text_1: "Black raisins or Kish Mish are dried grapes, which are considered to be the richesttasting raisins in the world; it has low sodium content, cholesterol-free, and naturally \n" +
      "contain antioxidants, potassium and fiber, benefiting your overall health; it helps \n" +
      "digestion and strengthens the immune system.\n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce our black raisins are grown in the vineyards of our farm, \n" +
      "located in the Samarkand region of the Republic of Uzbekistan, which one of the most \n" +
      "favorable places to grow the grapes.",
    product_text_2: "Sultana raisins are very popular due to their wide range of possible applications in the food \n" +
      "industry, that is, cooking, bakery, confectionery and cereal industries.\n" +
      "<br>" +
      "<br>" +
      "The texture of Sultana raisins is rich in nutrients, minerals and vitamins, so its regular use helps \n" +
      "to improve all body functions. It is good to know that the constant consumption of this valuable \n" +
      "nutrient is greatly beneficial to the health state of teeth and bones, as opposed to many other \n" +
      "sugars and sweets, and is also useful for prevention of osteoporosis",
    product_text_3: "Red raisins are made from red grapes and known for their delicious taste and dense \n" +
      "texture. They are also called sometimes as fire raisins since they are made from seedless \n" +
      "red grapes. \n" +
      "<br>" +
      "<br>" +
      "Red raisins are rich in antioxidants and an excellent source of vitamins C and K, help \n" +
      "regulating the blood sugar levels, reduce the risk of diabetes and contain natural \n" +
      "compounds that can help prevent the tooth decay and gum disease. \n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce our red raisins are grown in the vineyards of our farm, located in the Samarkand region of the Republic of Uzbekistan, which one of the most favorable places to grow the grapes. ",
    product_text_4: "Red raisins are made from red grapes and known for their delicious taste and dense \n" +
      "texture. They are also called sometimes as fire raisins since they are made from seedless \n" +
      "red grapes. \n" +
      "<br>" +
      "<br>" +
      "Red raisins are rich in antioxidants and an excellent source of vitamins C and K, help \n" +
      "regulating the blood sugar levels, reduce the risk of diabetes and contain natural \n" +
      "compounds that can help prevent the tooth decay and gum disease. \n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce our red raisins are grown in the vineyards of our farm, located in the Samarkand region of the Republic of Uzbekistan, which one of the most favorable places to grow the grapes. ",
    product_text_5: "Sultana raisins are very popular due to their wide range of possible applications in the food \n" +
      "industry, that is, cooking, bakery, confectionery and cereal industries.\n" +
      "<br>" +
      "<br>" +
      "The texture of Sultana raisins is rich in nutrients, minerals and vitamins, so its regular use helps \n" +
      "to improve all body functions. It is good to know that the constant consumption of this valuable \n" +
      "nutrient is greatly beneficial to the health state of teeth and bones, as opposed to many other \n" +
      "sugars and sweets, and is also useful for prevention of osteoporosis.",
    product_text_6: "Sultana raisins are very popular due to their wide range of possible applications in the food \n" +
      "industry, that is, cooking, bakery, confectionery and cereal industries.\n" +
      "<br>" +
      "<br>" +
      "The texture of Sultana raisins is rich in nutrients, minerals and vitamins, so its regular use helps \n" +
      "to improve all body functions. It is good to know that the constant consumption of this valuable \n" +
      "nutrient is greatly beneficial to the health state of teeth and bones, as opposed to many other \n" +
      "sugars and sweets, and is also useful for prevention of osteoporosis.\n",
    product_text_7: "Sultana raisins are very popular due to their wide range of possible applications in the food \n" +
      "industry, that is, cooking, bakery, confectionery and cereal industries.\n" +
      "<br>" +
      "<br>" +
      "The texture of Sultana raisins is rich in nutrients, minerals and vitamins, so its regular use helps \n" +
      "to improve all body functions. It is good to know that the constant consumption of this valuable \n" +
      "nutrient is greatly beneficial to the health state of teeth and bones, as opposed to many other \n" +
      "sugars and sweets, and is also useful for prevention of osteoporosis.",
    product_text_8: "The only difference between golden raisins and \"regular\" raisins is that golden raisins are yellow but not dark brown. In fact, both brown and yellow raisins can be made from the same grape variety. The only thing that distinguishes them is the way they are produced.\n" +
      "<br>" +
      "<br>" +
      "\"Golden\" raisins are slightly better suited for recipes that require for the raisins to be soaked first to make it plumper. Their look is different because of the sulfur dioxide, as used as a preservative to help the golden raisins to retain a lighter color.",
    product_text_9: "Sultana raisins are very popular due to their wide range of possible applications in the food \n" +
      "industry, that is, cooking, bakery, confectionery and cereal industries.\n" +
      "<br>" +
      "<br>" +
      "The texture of Sultana raisins is rich in nutrients, minerals and vitamins, so its regular use helps \n" +
      "to improve all body functions. It is good to know that the constant consumption of this valuable \n" +
      "nutrient is greatly beneficial to the health state of teeth and bones, as opposed to many other \n" +
      "sugars and sweets, and is also useful for prevention of osteoporosis.",
    production_title: "Production",
    production_text: "The production facilities of our Company are located in the Samarkand region of the Republic of\n" +
      "Uzbekistan and equipped with production lines and laboratories by the global leaders, which allows us\n" +
      "to meet all international quality standards applied for production of the raisins.\n" +
      "<br>" +
      "<br>" +
      "Every stage of production is important to us, which means we are to monitor all processes daily, since it\n" +
      "is the basics for the quality of the finished product.\n" +
      "<br>" +
      "<br>" +
      "In our activities, we strictly adhere to certain rules so that our products can meet all quality standards: ",
    production_text_1: "Our Company's specialists constantly monitor and control the entire production cycle, which ultimately\n" +
      "enriches our products with an intense and excellent taste",
    production_text_2: "We monitor all standards for the content of harmful chemical elements in products, to be confident in\n" +
      "terms of the safety of the product used",
    production_text_3: "Each manufactured batch of products shall pass thorough laboratory control",
    production_text_4: "Continuous improvement of the product quality by means of introduction of new technologies, and a\n" +
      "responsible approach in cultivation of grapes and its further processing.",
    production_step_text: "After drying the grapes, the feedstock is thoroughly washed and undergo 4-stage electronic sorting and\n" +
      "vacuum separation from foreign bodies. By using the laser-optical and X-ray methods we can sort raisins\n" +
      "by color, size, structure and inclusions of stone, glass, metal and foreign matters.\n" +
      "<br>" +
      "<br>" +
      "At the final stage of production, our specialists go through an additional manual sorting to remove\n" +
      "unnecessary particles.\n" +
      "<br>" +
      "<br>" +
      "Our experienced agricultural specialists carefully monitor every stage of production to ensure\n" +
      "exceptional quality of the product.",
    production_step_text_1: "After harvesting, we wash the collected grapes in running water.",
    production_step_text_2: "Alkali treatment",
    production_step_text_3: "We naturally sun-dry the grapes in the open air, with direct sunlight exposure, without using special drying chambers or equipment.",
    production_step_text_4: "After the grape drying process, raisins undergo a 4-stage electronic sorting and vacuum separation from foreign objects. Laser-optical and X-ray methods allow sorting raisins by color, size, structure, stones, glass, metal, and foreign materials.",
    production_step_text_5: "Following the removal of foreign objects, we rinse the raisins once again with a stream of clean water.",
    production_step_text_6: "In the final production stage, our specialists conduct additional manual sorting to eliminate any unwanted particles.",
    production_step_text_7: "The prepared raisins are then readied for shipment, packaged, and labeled in cardboard boxes or polypropylene bags with polyethylene liners.",
    production_big_team: "In our operations, we utilize production lines and technologies from leading equipment suppliers in the agricultural sector, such as the Belgian company “Tomra” and the Turkish company “Bigtem”. This comprehensive approach allows us to deliver additional quality to our customers.",
    quality_title: "Quality and certification",
    certification_title: "certification",
    certifications_title: "Certificates",
    certification_text: "One of our main priorities is to provide our partners with products \n" +
      "<br>" +
      "that fully meet the highest quality standards.",
    quality_text: "Our company’s specialists have deep knowledge in the field of agriculture, along with a high level of expertise, and continually work on improving all processes. This ultimately results in excellent production volume and quality.\n" +
      "<br>" +
      "<br>" +
      "The professional grape growing techniques the use of cutting-edge technologies in processing and storage, and the skilled and coordinated work of our qualified staff enable us to meet all the requirements of our customers, which is confirmed by the international certificate of quality management and food safety FSSC 22000.",
    contact_title: "Contact",
    contact_export: "Export Department",
    form_name: "Name",
    form_email: "E-mail",
    form_phone_number: "Phone number",
    form_message: "Message",
    form_submit: "Submit",
    form_request: "For any request",
    form_title: "Contact Us",
    product_text_more_1: "<b>Black raisins or Kish Mish</b> are dried grapes, which are considered to be the richesttasting raisins in the world; it has low sodium content, cholesterol-free, and naturally \n" +
      "contain antioxidants, potassium and fiber, benefiting your overall health; it helps \n" +
      "digestion and strengthens the immune system.\n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce our black raisins are grown in the vineyards of our farm, \n" +
      "located in the Samarkand region of the Republic of Uzbekistan, which one of the most \n" +
      "favorable places to grow the grapes. \n" +
      "<br>" +
      "<br>" +
      "Our experienced agricultural specialists carefully monitor every stage of production to \n" +
      "ensure exceptional quality of the product." +
      "<br>" +
      "<br>" +
      "Information about the product:",
    product_step_1_1: "<b>Place of cultivation and production:</b> Samarkand region, Republic of Uzbekistan",
    product_step_1_2: "<b>Harvest time:</b>  end of August\n" +
      "Number of berries per 100 g of product: 125-128 pcs.",
    product_step_1_3: "<b>Humidity:</b> 12.5% - 13%\n",
    product_step_1_4: "<b>Shape:</b> oval, semi-hollow texture, seedless",
    product_step_1_5: "<b>Color:</b> dark-blue",
    product_step_1_6: "<b>Taste:</b> sweet, without flavors",
    product_step_1_7: "<b>Smell:</b> typical fragrance of black raisins",
    product_step_1_8: "<b>Feedstock used:</b> does not contain GMOs and grown naturally.",
    product_step_1_9: "<b>Cleaning method:</b>  rinsing in clean flow water, \n" +
      "electronic 4-stage cleaning and sorting from foreign \n" +
      "bodies, metal detection, laser-optical and X-ray \n" +
      "methods",
    product_step_1_10: "<b>Sorting method:</b> by color, size, structure and \n" +
      "inclusions of stone, glass, metal and foreign matters",
    product_step_1_11: "<b>Certification:</b> International Certificate of Quality \n" +
      "Management and Food Safety <b>FSSC 22000</b>",
    product_step_1_12: "<b>Purpose:</b> for industrial use, raisins can also be \n" +
      "consumed on its own, used for cooking various \n" +
      "dishes, and as raw materials for other food products.",
    product_step_1_13: "<b>Packaging:</b> cardboard box with a 10 and 12.5 kg food \n" +
      "grade polyethylene liner.",
    product_step_1_14: "<b>Delivery:</b> by sea or by car container",
    product_step_1_15: "<b>Shelf life:</b> twelve months from the date of \n" +
      "production",
    product_text_more_2: "<b>Sultana raisins</b> are very popular due to their wide range of possible applications in the food \n" +
      "industry, that is, cooking, bakery, confectionery and cereal industries.\n" +
      "<br>" +
      "<br>" +
      "The texture of Sultana raisins is rich in nutrients, minerals and vitamins, so its regular use helps \n" +
      "to improve all body functions. It is good to know that the constant consumption of this valuable \n" +
      "nutrient is greatly beneficial to the health state of teeth and bones, as opposed to many other \n" +
      "sugars and sweets, and is also useful for prevention of osteoporosis.\n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce the Sultana raisins are grown in our own vineyards of our farm, \n" +
      "which is located in the Samarkand region of the Republic of Uzbekistan and is one of the most \n" +
      "favorable locations to grow the grapes. \n" +
      "<br>" +
      "<br>" +
      "Our experienced agricultural specialists carefully monitor every stage of production to ensure \n" +
      "exceptional quality of the product." +
      "<br>" +
      "<br>" +
      "Information about the product:",
    product_step_2_1: "<b>Place of cultivation and production:</b> Samarkand \n" +
      "region, Republic of Uzbekistan",
    product_step_2_2: "<b>Harvest time:</b> August",
    product_step_2_3: "<b>Size, mm:</b> 10+\n",
    product_step_2_4: "<b>Number of berries per 100 g of product:</b> 235-237 pcs.",
    product_step_2_5: "<b>Humidity:</b> 12%",
    product_step_2_6: "<b>Drying method:</b> solar drying, with alkali pre-washing",
    product_step_2_7: "<b>Shape:</b> oval, semi-hollow texture, seedless",
    product_step_2_8: "<b>Color:</b> light to dark brown",
    product_step_2_9: "<b>Taste:</b> sweet, delicious, attributed to raisins, without \n" +
      "flavors, slightly caramelized and sweet.",
    product_step_2_10: "<b>Smell:</b> typical fragrance of Sultana without an foreign \n" +
      "odor\n",
    product_step_2_11: "<b>Feedstock:</b> made from grapes grown in the own \n" +
      "vineyards of \"NNP Raisins\", treated with alkali before \n" +
      "solar drying, does not contain GMOs and grown \n" +
      "naturally.",
    product_step_2_12: "<b>Cleaning method:</b> rinsing in clean water, electronic \n" +
      "4-stage cleaning and sorting from foreign bodies, \n" +
      "metal detection, laser-optical and X-ray methods\n",
    product_step_2_13: "<b>Sorting method:</b> by color, size, structure and \n" +
      "inclusions of stone, glass, metal and foreign matters\n",
    product_step_2_14: "<b>Certification:</b> International Certificate of Quality \n" +
      "Management and Food Safety FSSC 22000",
    product_step_2_15: "<b>Purpose:</b> for industrial use, raisins can also be \n" +
      "consumed on its own, used for cooking various \n" +
      "dishes, and as raw materials for other food products.",
    product_step_2_16: "<b>Packaging:</b> cardboard box with a 10 and 12.5 kg food \n" +
      "grade polyethylene liner.",
    product_step_2_17: "<b>Delivery:</b> by sea or by car container",
    product_step_2_18: "<b>Shelf life:</b> twelve months from the date of \n" +
      "production",
    product_text_more_3: "Red raisins are made from red grapes and known for their delicious taste and dense \n" +
      "texture. They are also called sometimes as fire raisins since they are made from seedless \n" +
      "red grapes. \n" +
      "<br>" +
      "<br>" +
      "Red raisins are rich in antioxidants and an excellent source of vitamins C and K, help \n" +
      "regulating the blood sugar levels, reduce the risk of diabetes and contain natural \n" +
      "compounds that can help prevent the tooth decay and gum disease. \n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce our red raisins are grown in the vineyards of our farm, \n" +
      "located in the Samarkand region of the Republic of Uzbekistan, which one of the most \n" +
      "favorable places to grow the grapes. \n" +
      "<br>" +
      "<br>" +
      "Our experienced agricultural specialists carefully monitor every stage of production to \n" +
      "ensure exceptional quality of the product." +
      "<br>" +
      "<br>" +
      "Information about the product:",
    product_step_3_1: "<b>Place of cultivation and production:</b> Samarkand \n" +
      "region, Republic of Uzbekistan",
    product_step_3_2: "<b>Harvest time:</b> end of August",
    product_step_3_3: "<b>Size:</b> 5-10 mm",
    product_step_3_4: "<b>Number of berries per 100 g of product:</b> 370-375 pcs.",
    product_step_3_5: "<b>Humidity:</b> 11%\n",
    product_step_3_6: "<b>Texture:</b> soft, elastic, not over-dried, easy to bite \n" +
      "through and chew",
    product_step_3_7: "<b>Color:</b> homogeneous, from light red to reddish black",
    product_step_3_8: "<b>Taste:</b> sweet and sour",
    product_step_3_9: "<b>Smell:</b> typical fragrance of red raisins",
    product_step_3_10: "<b>Feedstock used:</b> does not contain GMOs and grown \n" +
      "naturally.",
    product_step_3_11: "<b>Cleaning method:</b> rinsing in clean water, electronic 4-\n" +
      "stage cleaning and sorting from foreign bodies, metal \n" +
      "detection, laser-optical and X-ray methods",
    product_step_3_12: "<b>Sorting method:</b> by color, size, structure and \n" +
      "inclusions of stone, glass, metal and foreign matters",
    product_step_3_13: "<b>Certification:</b> International Certificate of Quality \n" +
      "Management and Food Safety <b>FSSC 22000</b>",
    product_step_3_14: "<b>Purpose:</b> for industrial use, raisins can also be \n" +
      "consumed on its own, used for cooking various \n" +
      "dishes, and as raw materials for other food products.",
    product_step_3_15: "<b>Packaging:</b> cardboard box with a 10 and 12.5 kg food \n" +
      "grade polyethylene liner.",
    product_step_3_16: "<b>Delivery:</b> by sea or by car container",
    product_step_3_17: "<b>Shelf life:</b> twelve months from the date of \n" +
      "production",
    product_text_more_4: "Red raisins are made from red grapes and known for their delicious taste and dense \n" +
      "texture. They are also called sometimes as fire raisins since they are made from seedless \n" +
      "red grapes. \n" +
      "<br>" +
      "<br>" +
      "Red raisins are rich in antioxidants and an excellent source of vitamins C and K, help \n" +
      "regulating the blood sugar levels, reduce the risk of diabetes and contain natural \n" +
      "compounds that can help prevent the tooth decay and gum disease. \n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce our red raisins are grown in the vineyards of our farm, \n" +
      "located in the Samarkand region of the Republic of Uzbekistan, which one of the most \n" +
      "favorable places to grow the grapes. \n" +
      "<br>" +
      "<br>" +
      "Our experienced agricultural specialists carefully monitor every stage of production to \n" +
      "ensure exceptional quality of the product." +
      "<br>" +
      "<br>" +
      "Information about the product: ",
    product_step_4_1: "<b>Place of cultivation and production:</b> Samarkand \n" +
      "region, Republic of Uzbekistan",
    product_step_4_2: "<b>Harvest time:</b> end of August",
    product_step_4_3: "<b>Size, mm:</b> 10+",
    product_step_4_4: "<b>Number of berries per 100 g of product:</b> 190-194 pcs.",
    product_step_4_5: "<b>Humidity:</b> 11%-12%",
    product_step_4_6: "<b>Texture:</b> soft, elastic, not over-dried, easy to bite \n" +
      "through and chew",
    product_step_4_7: "<b>Color:</b> homogeneous, from light red to reddish black",
    product_step_4_8: "<b>Taste:</b> sweet and sour ",
    product_step_4_9: "<b>Smell:</b> typical fragrance of red raisins",
    product_step_4_10: "<b>Feedstock used:</b> does not contain GMOs and grown \n" +
      "naturally.",
    product_step_4_11: "<b>Cleaning method:</b> rinsing in clean water, electronic 4-\n" +
      "stage cleaning and sorting from foreign bodies, metal \n" +
      "detection, laser-optical and <X-ra></X-ra>y methods",
    product_step_4_12: "<b>Sorting method:</b> by color, size, structure and \n" +
      "inclusions of stone, glass, metal and foreign matters",
    product_step_4_13: "<b>Certification:</b> International Certificate of Quality \n" +
      "Management and Food Safety FSSC 22000",
    product_step_4_14: "<b>Purpose:</b> for industrial use, raisins can also be \n" +
      "consumed on its own, used for cooking various \n" +
      "dishes, and as raw materials for other food products.",
    product_step_4_15: "<b>Packaging:</b> cardboard box with a 10 and 12.5 kg food \n" +
      "grade polyethylene liner.",
    product_step_4_16: "<b>Delivery:</b> by sea or by car container",
    product_step_4_17: "<b>Shelf life:</b> twelve months from the date of \n" +
      "production",
    product_text_more_5: "<b>Sultana raisins</b> are very popular due to their wide range of possible applications in the food \n" +
      "industry, that is, cooking, bakery, confectionery and cereal industries.\n" +
      "<br>" +
      "<br>" +
      "The texture of Sultana raisins is rich in nutrients, minerals and vitamins, so its regular use helps \n" +
      "to improve all body functions. It is good to know that the constant consumption of this valuable \n" +
      "nutrient is greatly beneficial to the health state of teeth and bones, as opposed to many other \n" +
      "sugars and sweets, and is also useful for prevention of osteoporosis.\n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce the Sultana raisins are grown in our own vineyards of our farm, \n" +
      "which is located in the Samarkand region of the Republic of Uzbekistan and is one of the most \n" +
      "favorable locations to grow the grapes. \n" +
      "<br>" +
      "<br>" +
      "Our experienced agricultural specialists carefully monitor every stage of production to ensure \n" +
      "exceptional quality of the product." +
      "<br>" +
      "<br>" +
      "Information about the product:",
    product_step_5_1: "<b>Place of cultivation and production:</b> Samarkand \n" +
      "region, Republic of Uzbekistan",
    product_step_5_2: "<b>Harvest time:</b> August",
    product_step_5_3: "<b>Size:</b> 5-8 mm",
    product_step_5_4: "<b>Number of berries per 100 g of product:</b> 625-630 pcs.",
    product_step_5_5: "<b>Humidity:</b> 11% ",
    product_step_5_6: "<b>Texture:</b> soft, elastic, not over-dried, easy to bite \n" +
      "through and chew",
    product_step_5_7: "<b>Drying method:</b> solar drying, with alkali pre-washing",
    product_step_5_8: "<b>Shape:</b> oval, semi-hollow texture, seedless\n",
    product_step_5_9: "<b>Color</b> light to dark brown",
    product_step_5_10: "<b>Taste:</b> sweet, delicious, attributed to raisins, without \n" +
      "flavors, slightly caramelized and sweet.",
    product_step_5_11: "<b>Smell:</b> typical fragrance of Sultana without an foreign \n" +
      "odor",
    product_step_5_12: "<b>Feedstock:</b>made from grapes grown in the own \n" +
      "vineyards of \"NNP Raisins\", treated with alkali before \n" +
      "solar drying, does not contain GMOs and grown \n" +
      "naturally.\n" +
      "Cleaning method: rinsing in clean water, electronic 4-stage cleaning and sorting from foreign bodies, metal \n" +
      "detection, laser-optical and X-ray methods",
    product_step_5_13: "<b>Sorting method:</b> by color, size, structure and inclusions of \n" +
      "stone, glass, metal and foreign matters",
    product_step_5_14: "<b>Certification:</b> International Certificate of Quality \n" +
      "Management and Food Safety FSSC 22000",
    product_step_5_15: "<b>Purpose:</b> for industrial use, raisins can also be consumed \n" +
      "on its own, used for cooking various dishes, and as raw \n" +
      "materials for other food products.",
    product_step_5_16: "<b>Packaging:</b> cardboard box with a 10 and 12.5 kg food grade \n" +
      "polyethylene liner.",
    product_step_5_17: "<b>Delivery:</b> by sea or by car container",
    product_step_5_18: "<b>Shelf life:</b> twelve months from the date of production",
    product_text_more_6: "<b>Sultana raisins</b> are very popular due to their wide range of possible applications in the food \n" +
      "industry, that is, cooking, bakery, confectionery and cereal industries.\n" +
      "<br>" +
      "<br>" +
      "The texture of Sultana raisins is rich in nutrients, minerals and vitamins, so its regular use helps \n" +
      "to improve all body functions. It is good to know that the constant consumption of this valuable \n" +
      "nutrient is greatly beneficial to the health state of teeth and bones, as opposed to many other \n" +
      "sugars and sweets, and is also useful for prevention of osteoporosis.\n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce the Sultana raisins are grown in our own vineyards of our farm, \n" +
      "which is located in the Samarkand region of the Republic of Uzbekistan and is one of the most \n" +
      "favorable locations to grow the grapes. \n" +
      "<br>" +
      "<br>" +
      "Our experienced agricultural specialists carefully monitor every stage of production to ensure \n" +
      "exceptional quality of the product." +
      "<br>" +
      "<br>" +
      "Information about the product:",
    
    product_step_6_1: "<b>Place of cultivation and production:</b> Samarkand \n" +
      "region, Republic of Uzbekistan\n",
    product_step_6_2: "<b>Harvest time:</b> August",
    product_step_6_3: "<b>Size, mm:</b> 10+",
    product_step_6_4: "<b>Number of berries per 100 g of product:</b> 235-237 pcs.",
    product_step_6_5: "<b>Humidity:</b> 12%",
    product_step_6_6: "<b>Drying method:</b> solar drying, with alkali pre-washing",
    product_step_6_7: "<b>Shape:</b> oval, semi-hollow texture, seedless",
    product_step_6_8: "<b>Color:</b> light to dark brown\n",
    product_step_6_9: "<b>Taste:</b> sweet, delicious, attributed to raisins, without \n" +
      "flavors, slightly caramelized and sweet.\n",
    product_step_6_10: "<b>Smell:</b> typical fragrance of Sultana without an foreign \n" +
      "odor",
    product_step_6_11: "<b>Feedstock:</b> made from grapes grown in the own \n" +
      "vineyards of \"NNP Raisins\", treated with alkali before \n" +
      "solar drying, does not contain GMOs and grown \n" +
      "naturally.\n",
    product_step_6_12: "<b>Cleaning method:</b> rinsing in clean water, electronic 4-\n" +
      "stage cleaning and sorting from foreign bodies, metal \n" +
      "detection, laser-optical and X-ray methods",
    product_step_6_13: "<b>Sorting method:</b> by color, size, structure and inclusions \n" +
      "of stone, glass, metal and foreign matters",
    product_step_6_14: "<b>Certification:</b> International Certificate of Quality \n" +
      "Management and Food Safety <b>FSSC 22000</b>",
    product_step_6_15: "<b>Purpose:</b> for industrial use, raisins can also be consumed \n" +
      "on its own, used for cooking various dishes, and as raw \n" +
      "materials for other food products.",
    product_step_6_16: "<b>Packaging:</b> cardboard box with a 10 and 12.5 kg food \n" +
      "grade polyethylene liner.\n",
    product_step_6_17: "<b>Delivery:</b> by sea or by car container",
    product_step_6_18: "<b>Shelf life:</b> twelve months from the date of production",
    
    product_text_more_7: "<b>Sultana raisins</b> are very popular due to their wide range of possible applications in the food \n" +
      "industry, that is, cooking, bakery, confectionery and cereal industries.\n" +
      "<br>" +
      "<br>" +
      "The texture of Sultana raisins is rich in nutrients, minerals and vitamins, so its regular use helps \n" +
      "to improve all body functions. It is good to know that the constant consumption of this valuable \n" +
      "nutrient is greatly beneficial to the health state of teeth and bones, as opposed to many other \n" +
      "sugars and sweets, and is also useful for prevention of osteoporosis.\n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce the Sultana raisins are grown in our own vineyards of our farm, \n" +
      "which is located in the Samarkand region of the Republic of Uzbekistan and is one of the most \n" +
      "favorable locations to grow the grapes. \n" +
      "<br>" +
      "<br>" +
      "Our experienced agricultural specialists carefully monitor every stage of production to ensure \n" +
      "exceptional quality of the product.\n" +
      "<br>" +
      "<br>" +
      "Information about the product:",
    
    product_step_7_1: "<b>Place of cultivation and production:</b> Samarkand \n" +
      "region, Republic of Uzbekistan",
    product_step_7_2: "<b>Harvest time:</b> August",
    product_step_7_3: "<b>Number of berries per 100 g of product:</b> 185-188 pcs.",
    product_step_7_4: "<b>Humidity:</b> 12%\n",
    product_step_7_5: "<b>Drying method:</b> solar drying, with alkali pre-washing",
    product_step_7_6: "<b>Shape:</b> oval, semi-hollow texture, seedless",
    product_step_7_7: "<b>Color:</b> light to dark brown",
    product_step_7_8: "<b>Taste:</b> sweet, delicious, attributed to raisins, without \n" +
      "flavors, slightly caramelized and sweet.\n",
    product_step_7_9: "<b>Smell:</b> typical fragrance of Sultana without an foreign \n" +
      "odor",
    product_step_7_10: "<b>Feedstock:</b> made from grapes grown in the own \n" +
      "vineyards of \"NNP Raisins\", treated with alkali before \n" +
      "solar drying, does not contain GMOs and grown \n" +
      "naturally.",
    product_step_7_11: "<b>Cleaning method:</b> rinsing in clean water, electronic 4-\n" +
      "stage cleaning and sorting from foreign bodies, metal \n" +
      "detection, laser-optical and X-ray methods",
    product_step_7_12: "<b>Sorting method:</b> by color, size, structure and \n" +
      "inclusions of stone, glass, metal and foreign matters",
    product_step_7_13: "<b>Certification:</b> International Certificate of Quality \n" +
      "Management and Food Safety <b>FSSC 22000</b>",
    product_step_7_14: "<b>Purpose:</b> for industrial use, raisins can also be \n" +
      "consumed on its own, used for cooking various \n" +
      "dishes, and as raw materials for other food products.",
    product_step_7_15: "<b>Packaging:</b> cardboard box with a 10 and 12.5 kg food \n" +
      "grade polyethylene liner.",
    product_step_7_16: "<b>Delivery:</b> by sea or by car container",
    product_step_7_17: "<b>Shelf life:</b> twelve months from the date of \n" +
      "production",
    product_text_more_8: "The only difference between golden raisins and \"regular\" raisins is that golden raisins are yellow \n" +
      "but not dark brown. In fact, both brown and yellow raisins can be made from the same grape \n" +
      "variety. The only thing that distinguishes them is the way they are produced.\n" +
      "<br>" +
      "<br>" +
      "\"Golden\" raisins are slightly better suited for recipes that require for the raisins to be soaked first \n" +
      "to make it plumper. Their look is different because of the sulfur dioxide, as used as a \n" +
      "preservative to help the golden raisins to retain a lighter color.\n" +
      "<br>" +
      "<br>" +
      "The golden raisins can be a good alternative to all artificial sugars, chocolate and sweets due to \n" +
      "the large amount of nutrients, minerals and vitamins contained in their texture.\n" +
      "In addition, the golden raisins have high energy value, but they are low in calories and sugar, so \n" +
      "it may be the best choice for diabetes. \n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce the \"Golden\" raisins are grown in our own vineyards of our farm, \n" +
      "located in the Samarkand region of the Republic of Uzbekistan, which one of the most \n" +
      "favorable places to grow the grapes.\n" +
      "<br>" +
      "<br>" +
      "Our experienced agricultural specialists carefully monitor every stage of production to ensure \n" +
      "exceptional quality of the product.\n" +
      "<br>" +
      "<br>" +
      "Information about the product:",
    product_step_8_1: "<b>Place of cultivation and production:</b> Samarkand \n" +
      "region, Republic of Uzbekistan\n",
    product_step_8_2: "<b>Harvest time:</b> September",
    product_step_8_3: "<b>Number of berries per 100 g of product:</b> 310-318 pcs.",
    product_step_8_4: "<b>Humidity:</b> 11%",
    product_step_8_5: "<b>Shape:</b> oval, plump, seedless, soft texture\n",
    product_step_8_6: "<b>Color:</b> gold, light-yellow",
    product_step_8_7: "<b>Taste:</b> sweet, without flavors",
    product_step_8_8: "<b>Smell:</b> typical fragrance of \"Golden\" raisins",
    product_step_8_9: "<b>Feedstock used:</b> does not contain GMOs and grown \n" +
      "naturally.",
    product_step_8_10: "<b>Cleaning method:</b> rinsing in clean water, electronic 4-\n" +
      "stage cleaning and sorting from foreign bodies, metal \n" +
      "detection, laser-optical and X-ray methods",
    product_step_8_11: "<b>Sorting method:</b> by color, size, structure and \n" +
      "inclusions of stone, glass, metal and foreign matters",
    product_step_8_12: "<b>Certification:</b> International Certificate of Quality \n" +
      "Management and Food Safety <b>FSSC 22000</b>\n" +
      " amber color, perfect shape and amazing smell make \n" +
      "this variety of raisins a desirable product in the \n" +
      "kitchen and is used in cooking as an excellent \n" +
      "addition to desserts and unsweetened dishes",
    product_step_8_13: "<b>Packaging:</b> cardboard box with a 10 and 12.5 kg food \n" +
      "grade polyethylene liner.",
    product_step_8_14: "<b>Delivery:</b> by sea or by car container",
    product_step_8_15: "<b>Shelf life:</b> twelve months from the date of \n" +
      "production",
    product_text_more_9: "The only difference between golden raisins and \"regular\" raisins is that golden raisins are \n" +
      "yellow but not dark brown. In fact, both brown and yellow raisins can be made from the \n" +
      "same grape variety. The only thing that distinguishes them is the way they are produced.\n" +
      "<br>" +
      "<br>" +
      "\"Golden\" raisins are slightly better suited for recipes that require for the raisins to be soaked \n" +
      "first to make it plumper. Their look is different because of the sulfur dioxide, as used as a \n" +
      "preservative to help the golden raisins to retain a lighter color.\n" +
      "<br>" +
      "<br>" +
      "The golden raisins can be a good alternative to all artificial sugars, chocolate and sweets \n" +
      "due to the large amount of nutrients, minerals and vitamins contained in their texture.\n" +
      "In addition, the golden raisins have high energy value, but they are low in calories and \n" +
      "sugar, so it may be the best choice for diabetes. \n" +
      "<br>" +
      "<br>" +
      "The grapes we use to produce the \"Golden\" raisins are grown in our own vineyards of our \n" +
      "farm, located in the Samarkand region of the Republic of Uzbekistan, which one of the \n" +
      "most favorable places to grow the grapes.\n" +
      "<br>" +
      "<br>" +
      "Our experienced agricultural specialists carefully monitor every stage of production to \n" +
      "ensure exceptional quality of the product." +
      "<br>" +
      "<br>" +
      "Information about the product:",
    product_step_9_1: "<b>Place of cultivation and production:</b> Samarkand region, \n" +
      "Republic of Uzbekistan",
    product_step_9_2: "<b>Harvest time:</b> September",
    product_step_9_3: "<b>Number of grape berries per 100 g of product:</b> 245-248 pcs.",
    product_step_9_4: "<b>Humidity:</b> 12%",
    product_step_9_5: "<b>Shape:</b> oval, plump, seedless, soft texture",
    product_step_9_6: "<b>Color:</b> gold, light-yellow ",
    product_step_9_7: "<b>Taste:</b> sweet, without flavors",
    product_step_9_8: "<b>Smell:</b> typical fragrance of \"Golden\" raisins",
    product_step_9_9: "<b>Feedstock used:</b> does not contain GMOs and grown \n" +
      "naturally.",
    product_step_9_10: "<b>Cleaning method:</b> rinsing in clean water, electronic 4-stage \n" +
      "cleaning and sorting from foreign bodies, metal detection, \n" +
      "laser-optical and X-ray methods",
    product_step_9_11: "<b>Sorting method:</b> by color, size, structure and \n" +
      "inclusions of stone, glass, metal and foreign matters\n",
    product_step_9_12: "<b>Certification:</b> International Certificate of Quality \n" +
      "Management and Food Safety FSSC 22000",
    product_step_9_13: "<b>Purpose:</b> amber color, perfect shape and amazing \n" +
      "smell make this variety of raisins a desirable product \n" +
      "in the kitchen and is used in cooking as an excellent \n" +
      "addition to desserts and unsweetened dishes.",
    product_step_9_14: "<b>Packaging:</b> cardboard box with a 10 and 12.5 kg food \n" +
      "grade polyethylene liner.",
    product_step_9_15: "<b>Delivery:</b> by sea or by car container",
    product_step_9_16: "<b>Shelf Life:</b> twelve months from the date of \n" +
      "production",
  },
}


let updating = false

const handleScroll = () => {
  if (window.scrollY <= thresholdMinus || window.pageYOffset <= thresholdMinus) {
    // if (window.location.pathname === "/" || window.location.pathname === "/index.html") {
    //   $nav.classList.remove(fixedClass)
    // } else {
    $nav.classList.remove(fixedClass)
    // }
  } else {
    $nav.classList.add(fixedClass)
  }
  updating = false
}
requestAnimationFrame(handleScroll)
window.onscroll = () => {
  if (updating) return
  else {
    updating = true
    requestAnimationFrame(handleScroll)
  }
  
  let elementId = ""
  // document.querySelectorAll('[data-nav]').forEach((e, i) => elementId == "" ? (isVisible(e) ? elementId = e.getAttribute("data-nav") : elementId) : elementId)
  // document.querySelectorAll('[data-target]').forEach(e => e.classList.remove("active"))
  // document.querySelector(`[data-target="${elementId}"]`)?.classList?.add("active")
}

function isVisible(elm) {
  let rect = elm.getBoundingClientRect();
  let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom <= 250 || rect.top - viewHeight >= 0);
}

let resizeSlider = false

window.addEventListener('resize', function (event) {
  if (window.matchMedia("(max-width: 992px)").matches) {
    resizeSlider = true
  }
}, true);

document.querySelectorAll(".nav-link").forEach(item => {
  if (window.location.pathname === `/${item.getAttribute("href")}` || (window.location.pathname !== `/${item.getAttribute("href")}` && window.location.pathname.includes("product_") && item.getAttribute("href") === "products.html")) {
    item.classList.add("active")
  } else {
    item.classList.remove("active")
  }
})

const swiper = new Swiper(".vertical-swiper", {
  direction: "vertical",
  simulateTouch: false,
  allowTouchMove: false,
  speed: 1000,
  autoplay: {
    delay: 3500,
    disableOnInteraction: true,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});


document.getElementById("current-year").innerHTML = new Date().getFullYear()

/* Language */
localStorage.setItem("lang", "en")
let storageLang = localStorage.getItem("lang") ? localStorage.getItem("lang") : localStorage.setItem("lang", "en")

const changeLang = (langs) => {
  storageLang = langs
  localStorage.setItem("lang", langs)
  document.querySelectorAll("[data-lang-name]").forEach(item => {
    item.innerHTML = language?.[langs]?.[item?.dataset?.langName]
  })
}

document.querySelectorAll(".lang-select").forEach(item => {
  let optionNames = item.options
  item.addEventListener("click", (e) => {
    Object.values(item.options).forEach(option => {
      if (option.value === e.target.value) {
        option.classList.add("active")
      } else {
        option.classList.remove("active")
      }
    })
    changeLang(e.target.value)
  })
})

changeLang(`${storageLang ?? "en"}`)

document.querySelector("#form-contact")?.addEventListener("submit", (evt) => {
  evt.preventDefault()
  const tg_name = document.querySelector("#first_name").value
  const tg_email = document.querySelector("#email").value
  const tg_phone = document.querySelector("#phone").value
  const tg_subject = document.querySelector("#theme").value
  const tg_message = document.querySelector("#message").value
  
  if (tg_name.length < 3 || !tg_email || tg_phone.length < 9 || tg_subject < 3 || tg_message < 3) {
    return null
  } else {
    $?.ajax({
      url: "https://api.telegram.org/bot6404263006:AAF8NPnOR7qjoRA2PVK_la3zVSMX8qxuCJ0/sendMessage",
      type: "get", //send it through get method
      data: {
        chat_id: 569288138,
        text: "🔔Lifetime Stones Заявка🔔" + "\n" + "\n" + "\n" + "<b>Имя: </b>" + tg_name + "\n" + "<b>Электронная почта*: </b>" + tg_email + "\n" + "<b>Телефон*: </b>" + "\n" + tg_phone + "\n" + "<b>Тема*: </b>" + "\n" + tg_subject + "\n" + "<b>Сообщение*: </b>" + "\n" + tg_message,
        parse_mode: "html"
      },
      success: function (response) {
        document.getElementById("form-contact").reset();
        document.querySelector(".thx-text").classList.remove("d-none")
        
        setTimeout(() => {
          document.querySelector(".thx-text").classList.add("d-none")
        }, 3000)
        // document.getElementById("message_tg").reset();
        // document.getElementById("send_tg").reset();
        // document.getElementById("success").classList.remove("d-none")
        
      },
      error: function (xhr) {
        //Do Something to handle error
      }
    });
  }
})


let btn = document.querySelector(".mode"),
  btn_img = document.querySelector("#mode"),
  mode_theme = window.localStorage.getItem("dark");
btn?.addEventListener("click", () => {
  document.querySelector("html")?.toggleAttribute("data-dark");
  let dark = $("html").attr("data-dark") === "";
  btn_img.src = dark ? "images/moon.png" : "images/sun.png";
  window.localStorage.setItem("dark", dark ? "1" : "0")
});

document.querySelectorAll(".blog--video")?.forEach(item => {
  item.nextElementSibling?.addEventListener("click", () => {
    item.play()
    item.setAttribute("controls", "")
    item.nextElementSibling.classList.add("active")
    item.style.objectFit = "contain"
  })
  
  item.addEventListener("click", (e) => {
    if (item.paused || item.ended) {
      item.play()
      item.setAttribute("controls", "")
      item.nextElementSibling.classList.add("active")
      item.style.objectFit = "contain"
    } else {
      item.pause()
      item.removeAttribute("controls")
      item.nextElementSibling.classList.remove("active")
    }
  });
})

function onReady(callback) {
  var intervalId = window.setInterval(function () {
    if (document.getElementsByTagName('body')[0] !== undefined) {
      window.clearInterval(intervalId);
      callback.call(this);
    }
  }, 1000);
}

function setVisible(selector, visible) {
  document.querySelector(selector).style.opacity = visible ? '1' : '0';
  
  
  if (!visible) {
    setTimeout(() => {
      document.querySelector(selector).style.zIndex = visible ? '9999999' : '-10';
    }, 300)
  }
  
}

onReady(function () {
  setVisible('.main-body', true);
  setVisible('#loading', false);
  setVisible('#loading_2', false);
});

document.querySelectorAll('a').forEach(item => {
  item.addEventListener('click', function (e) {
    e.preventDefault();
    
    const destination = this.getAttribute('href');
    const body_element = document.querySelector('.main-body');
    
    if (destination.includes("tel:") || destination.includes("mailto:") || destination.includes("#about")) {
      window.location.href = destination;
    } else {
      const loader = document.querySelector('#loading');
      const loader2 = document.querySelector('#loading_2');
      loader.style.opacity = 1;
      loader.style.zIndex = 9999999;
      loader2.style.opacity = 1;
      loader2.style.zIndex = 9999999;
      body_element.style.opacity = 0
      
      
      setTimeout(() => {
        window.location.href = destination;
      }, 980)
    }
  })
})


